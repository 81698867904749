import React, { useEffect } from 'react';
import Piechart from './visuals/Piechart';
import DualLinechart from './visuals/DualLinechart';
import { colorPalette as clr, lblDev, lblWeeks} from '../SharedCode';

function Demo() {
    let pieAspectRatio;
    pieAspectRatio = window.screen.width > 991 ? pieAspectRatio = 1.7 : pieAspectRatio = 1.2;
       
    const palettePayments = {
        lblX: clr.cream,
        titleX:clr.goldenrod,
        lblY: clr.cream,
        titleY:clr.goldenrod,
        chartTitle:clr.cream,
        legendtxt:clr.cream,
        grid: clr.brown,
    }
    const paletteProgress = {
        lblX: clr.darkbrown,
        titleX:clr.redish,
        lblY: clr.darkbrown,
        titleY:clr.redish,
        chartTitle:clr.redish,
        legendtxt:clr.darkbrown,
        grid: clr.lightbrown,
    }

    const datasetProgress = [
        {
            label: 'Progress',
            data: [0, 15, 25, 35, 50, 70, 90, 110, 130, 150, 165, 175, 185],
            borderColor: clr.redish,
            backgroundColor: clr.redish,
        },
        {
            label: 'Schedule',
            data: [0, 14, 28, 42, 56, 70, 84, 98, 112, 126, 140, 154, 168],
            borderColor: clr.carolina,
            backgroundColor: clr.carolina,
        },
    ]
    const datasetPayments = [
        {
            label: 'Payments',
            data: [0, 1500, 2500, 3500, 4500, 6000, 7500, 9000, 10000, 11000, 11500, 12000, 13000, 14500, 16000, 18000],
            borderColor: clr.cream,
            backgroundColor: clr.cream,
        },
        {
            label: 'Schedule',
            data: [0, 1500, 3000, 4500, 6000, 7500, 9000, 10500, 12000, 13500, 15000, 16500, 18000],
            borderColor: clr.carolina,
            backgroundColor: clr.carolina,
        },
    ]
    
    useEffect(() => {
        document.title = "Progress Graph - Project Development";
      }, []);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12 monitor-statement'>
                    Once the project is finalized, a login/password is generated for the client to monitor the progress on the project.
                </div>
                <div className='col-12 col-lg-6 col-stat' style={{ backgroundColor: clr.darkbrown }}>
                    <Piechart lbl={["Completed", "Remaining"]} data={[10, 3]} colours={[clr.brown, clr.cream]} lblColor={clr.cream} title={'Schedule'} titleColor={clr.white} pieAR={pieAspectRatio} />
                </div>
                <div className='col-12 col-lg-6 col-stat'>
                    <Piechart lbl={["Completed", "Remaining"]} data={[9, 4]} colours={[clr.brown, clr.darkbrown]} lblColor={clr.darkbrown} title={'Progress'} titleColor={clr.redish} pieAR={pieAspectRatio} />
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-lg-6 col-stat'>
                    <DualLinechart lblData = {lblDev} datasets={datasetProgress} palette={paletteProgress} xAxis='T A S K S' yAxis='D A Y S' title='Progress vs Schedule' />
                </div>
                <div className='col-12 col-lg-6 col-stat' style={{ backgroundColor: clr.darkbrown }}>
                    <DualLinechart lblData = {lblWeeks} datasets={datasetPayments} palette={palettePayments} xAxis='W E E K S' yAxis='P A Y M E N T S' title='Payments vs Schedule' />
                </div>
            </div>
        </div>
    )
}

export default Demo